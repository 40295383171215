.navigation-slides .slick-slide {
  padding: 15px;
}
.navigation-slides .slick-slide > div {
  background: white;
  padding: 15px;
  transition: 1s all;
}
.navigation-slides .slick-slide:not(.slick-current) > div {
  filter: blur(2px);
}
.navigation-slides .slick-current > div {
  box-shadow: 0px 6px 6px 0 rgba(0,0,0,0.5);
}

.Navigation-Group {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}