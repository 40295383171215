.colorPicker:hover {
    border:5px solid rgb(63, 62, 62);
  }

.colorPicker {
    border-radius:15px;
    width:30px;
    height:30px;
    border:1px solid #AAA;
  }
.colorPickerSelected {
    border-radius:15px;
    width:30px;
    height:30px;
    border:4px solid #000;
  }

.colorPicker_yellow {
        background-color: #FBBD08;
    }

.colorPicker_orange {
        background-color: #f26202;
    }

.colorPicker_red {
        background-color: #DB2828;
    }

.colorPicker_olive {
        background-color: #B5CC18;
    }

.colorPicker_green {
        background-color: #21BA45;
    }

    .colorPicker_teal {
        background-color: #00B5AD;
    }

    .colorPicker_blue {
        background-color: #2185D0;
    }

.colorPicker_violet {
        background-color: #6435C9;
    }

.colorPicker_purple {
        background-color: #A333C8;
    }

.colorPicker_pink {
        background-color: #E03997;
    }

.colorPicker_brown {
        background-color: #A5673F;
    }

    .colorPicker_grey {
        background-color: #767676;
    }