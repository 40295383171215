.link-help {
  vertical-align: super;
  margin-left: .25rem;
  font-size: .5rem;
}
.link-help:hover {
  cursor: pointer;
}
.link-help i {
  margin-right: 0 !important;
}