.stacked-icon-group {
  position: relative;
  display: inline-block;
  font-size: 2rem !important;
  width: 2rem !important;
  height: 2rem !important;
  line-height: 2rem !important;
  vertical-align: middle;
}
.stacked-icon-group.mini {
  font-size: .4rem !important;
  line-height: .4rem !important;
  width: .4rem !important;
  height: .4rem !important;
}
.stacked-icon-group.tiny {
  font-size: .5rem !important;
  line-height: .5rem !important;
  width: .5rem !important;
  height: .5rem !important;
}
.stacked-icon-group.small {
  font-size: .75rem !important;
  line-height: .75rem !important;
  width: .75rem !important;
  height: .75rem !important;
}
.stacked-icon-group.large {
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.stacked-icon-group.big {
  font-size: 2rem !important;
  line-height: 2rem !important;
  width: 2rem !important;
  height: 2rem !important;
}
.stacked-icon-group.huge {
  font-size: 4rem !important;
  line-height: 4rem !important;
  width: 4rem !important;
  height: 4rem !important;
}
.stacked-icon-group.massive {
  font-size: 8rem !important;
  line-height: 8rem !important;
  width: 8rem !important;
  height: 8rem !important;
}
.stacked-icon-group i {
  position: absolute;
  left: 0;
  text-align: center !important;
  margin: 0 !important;
  font-size: 1rem !important;
  line-height: 2rem !important;
  width: 2rem !important;
  height: 2rem !important;
}
.stacked-icon-group i.mini {
  font-size: .4rem !important;
}
.stacked-icon-group i.tiny {
  font-size: .5rem !important;
}
.stacked-icon-group i.small {
  font-size: .75rem !important;
}
.stacked-icon-group i.large {
  font-size: 1.5rem !important;
}
.stacked-icon-group i.big {
  font-size: 2rem !important;
}
.stacked-icon-group i.right {
  text-align: right !important;
}
.stacked-icon-group i.left {
  text-align: left !important;
}