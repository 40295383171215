#toolbar-calendar {
  user-select: none;
}
#toolbar-calendar .rbc-month-row {
  cursor: pointer;
}
.toolbar-section {
  display: flex;
  align-items: center;
}
.date-navigation {
  font-size: 1.5rem;
}
.date-navigation i:hover {
  cursor: pointer;
}
#toolbar-calendar .date-label {
  cursor: pointer;
}
.view-select {
  margin-top: 1rem;
  width: 100%;
}
@media (min-width: 768px) {
  .view-select {
    margin-top: 0;
    width: initial;
    float: right;
  }
}
.pop-selector {
  min-width: 400px !important;
}
.pop-selector:before {
  display: none;
}
.pop-selection {
  width: 100%;
}
.pop-selection .rbc-row-content {
  min-height: 50px !important;
}
.pop-selection-toolbar {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  user-select: none;
}
.pop-selection-toolbar i:hover {
  cursor: pointer;
}

.year-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
}

.go-to-today {
  user-select: none;
  color: #18BC9C;
  text-decoration: underline;
  cursor: pointer;
}
.tags-select {
  float: right;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .pop-selector {
    min-width: 100% !important;
    left: 0 !important;
  }
  .ui.popup:before {
    display: none !important;
  }
  .pop-selector .grid {
    flex-direction: column !important;
    max-width: 100%;
  }
  .pop-selector .column {
    width: 100% !important;
  }
  .pop-selector .selection-month {
    width: 100% !important;
  }
  .tags-select {
    float: none;
    width: 100%;
    margin-top: 20px;
  }
  .date-navigation {
    font-size: 1rem;
  }
}