.footer-pagination {
  justify-content: space-between;
}
.item-limit-selection {
  user-select: none;
}
.footer-element {
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-per-page {
  display: flex;
  width: 200px;
}
.wrapper-text:first-child {
  padding-right: 1rem;
}
.wrapper-text:last-child {
  padding-left: 1rem;
}
.pagination-selection {
  float: right;
}
@media (max-width: 767px) {
  .footer-element {
    display: flex !important;
  }
}